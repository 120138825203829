.my-select {
  color: black;
  width: 100%;
  max-width: 300px;
}

.quat-container {
  //border: 1px solid red;
  font-size: rem(34px);
  font-weight: bold;
  width: 100%;
  max-width: 100px;
  justify-content: space-evenly;
  color: rgb(211, 194, 169);
  position: relative;
  bottom: 7%;
}

.quat-container p {
  cursor: pointer;
}

.step2Inputs {
  //border: 1px solid red;
  justify-content: center !important;
  //padding-left: 1%;
}

.step2Inputs > * {
  margin-left: 50px;
}

.installDate {
  //border: 1px solid red;
  width: 32vw !important;
  //border: none;
  //color: rgba(255, 255, 255, 0.8) !important;
  font-size: rem(16px);
}

.my-wizard {
  //border: 1px solid red;
  height: 100vh !important;
  position: relative;
}

.my-wizard-container {
  .card-title {
    margin-bottom: 0 !important;
    line-height: 1;
    padding: 1rem 0;
  }
}

.card-wizard {
  .btn-simple {
    margin: 0;
    margin-left: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
  }
  .wizard-navigation {
    margin: 3rem;
  }
  .card-body {
    margin-top: 0;
  }
  .card-footer {
    display: flex;
    height: 70px;
    // flex-direction: row-reverse;
    // align-items: center;
    // justify-content: space-between;
    background-color: transparent !important;
    padding: 1rem 0;
    position: relative;

    div:nth-child(1),
    div:nth-child(2) {
      float: none !important;
      position: absolute;
      //   display: flex !important;
      margin: 0;
      padding: 0;
    }
    div:nth-child(1) {
      bottom: 0;
      right: 0;
      //   position: absolute;
    }
    div:nth-child(2) {
      bottom: 0;
      left: 0;
      //   position: absolute;
    }
  }
}

.my-wizard-container {
  //border: 1px solid red;
  //background-color: #1a1a31;
  background: transparent;
  min-height: 110vh;
}

.addProduct-btn {
  align-self: center;
  margin-top: 2%;
  height: 40px;
  //border-radius: 0%;
}

.wizard-btns {
  position: relative;
  //   top: 4vw;
}

.react-date-picker {
  button {
    padding: 0;
    margin-right: 1rem;
  }
  svg {
    stroke: #fff !important;
    color: #fff !important;
  }
}


// .goBack-btn-c {
//   background-color: yellowgreen !important;
// }