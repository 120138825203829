.installProfile-container {
  justify-content: center;
}
.my-card-user {
  height: inherit !important;
}

.installProfile-card {
  //border: 1px solid red;
  position: relative;
  right: 24%;
}

.managerProfile-container {
  .author {
    margin-top: 0 !important;
  }
  h4 {
    margin-bottom: 0.25rem;
  }
}

.my-cardBody {
  justify-content: space-evenly;
  margin-left: 15%;
  //align-content: space-between;
  //margin: 0 auto;
  //border: 1px solid red;
  // position: relative;
  // top: 10px;
  // left: 9vw;
}

.testt {
  margin-left: 0%;
}

.profile-footer {
  justify-content: space-evenly;
}

.edit-btn5 {
  width: 11%;
}
.save-btn-editProfile {
  width: 11.2%;
}

.profile-edit-inputs-container {
  //border: 1px solid red;
  justify-content: space-between;
  //justify-self: space-evenly;
}

.profile-inputs-box1 {
  position: relative;
  top: 3.5vh;
}

.btn-fill {
  width: 250px;
}

.box222 {
  position: relative;
  left: 12%;
}


.downInput {
  padding-top: 2.5%;
} 