.login-card-container {
  height: 700px;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  top: 20px;
  //right: 100px;
}

.login-card {
  background-color: azure;
}

.login-cardBody {
  position: relative;
  bottom: 80px;
}

.login-cardBody h2 {
  position: absolute;
  bottom: 470px;
  left: 0px;
  font-size: 60px;
  font-weight: bolder;
  z-index: 10;
}

.loginInput {
  max-width: 500px;
  height: 50px;
  color: black !important;
}

.loginbtn {
  max-width: 500px;
  height: 50px;
  font-size: 20px;
  cursor: pointer;
  background-color: rgb(150, 156, 156);
}

.login-page-container {
  justify-content: space-between;
}

.inputs-container {
  align-self: center;
  width: 500px;
}

.log-inputs-container {
  position: relative;
  top: rem(70px);
  justify-content: space-around;
}

.login-btn-container {
  position: relative;
  top: 150px;
  text-align: center;
}

.log-inputs-container input {
  font-size: 20px;
}

.pass-login-input {
  position: relative;
  top: 20px;
}

.signup-link:hover {
  color: black;
  background-color: rgba(168, 85, 157, 0.637);
}

.pass-icon {
  position: absolute;
  right: 20px;
  top: 86%;
  font-size: 20px;
  cursor: pointer;
}

.content {
  position: relative !important;
  top: 100px !important;
}

.cardBody {
  position: relative;
  top: 20px;
}
