.files-table-container {
    position: relative;
    top: 0px;
    height: 300px;
    overflow-y: auto;
}
.th-row {
    text-align: center !important;
    font-weight: bold !important;
    background-color: rgba(100, 116, 151, 0.521) !important;
}

.td-file {
    border: none;
    color: rgb(8, 8, 83) !important;
    cursor: pointer;
}

.my-table-head {
    //position: fixed;
}


