#signup {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  .signup-title {
    text-align: center;
    position: relative;
    top: 20px;
  }
  .tabs-container {
    display: flex;
    height: 100%;
    flex: 1;
    .tab-space {
      padding: 0;
      padding-top: 1rem;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    z-index: 1;
    padding: 1rem;

    button {
      width: 250px;
    }
  }
}

.adress {
  color: rgba(19, 13, 13, 0.8) !important;
}

.css-yk16xz-control,
.css-1pahdxg-control {
  background-color: transparent !important;
  border-color: #2b3553 !important;
  border-radius: 0.4285rem;
  font-size: 0.75rem;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
}

.css-1uccc91-singleValue {
  color: rgba(255, 255, 255, 0.8) !important;
}

.css-1g6gooi {
  color: rgba(255, 255, 255, 0.8) !important;
}

.gen-form {
  position: relative;
  top: 100px;
}

.custom-switch {
  .custom-control-label {
    margin-bottom: 0px;
    line-height: 22px;
    vertical-align: middle;
    &::before {
      height: 22px;
      width: 45px;
      border: none;
      // background-image: url("../../assets/img/darken-night-sky-full-of-stars-fantasy-animation-made-of-magical-sparkly-light-particles-flickering-on-a-purple-blue-gradient-background_bajacpz7x_thumbnail-full01.png");
      background-color: rgb(104, 104, 104);
      background-position: center center;
      background-size: cover;
    }
    &::after {
      height: 18px;
      width: 18px;
      background-color: rgba(156, 156, 156, 0.9);
      transition: all 0.25s ease-out;
    }
  }
  .custom-control-input:checked {
    ~ .custom-control-label::before {
      // background-image: url("../../assets/img/night-sky-full-of-stars-fantasy-animation-made-of-magical-sparkly-light-particles-flickering-on-a-purple-blue-gradient-background_bajacpz7x_thumbnail-full01.png");
      background-color: rgb(224, 11, 142);
    }
    ~ .custom-control-label::after {
      background-color: white;
      transform: translateX(23px);
    }
  }
  &:hover {
    .custom-control-label::after {
      width: 23px;
    }
    .custom-control-input:checked ~ .custom-control-label::after {
      margin-left: -5px;
    }
  }
}

.inst-signup-card {
  padding-top: 0px;
  position: relative;
  bottom: 100px;
  max-height: rem(725px);
}

.generic-signup-card {
  padding-top: 0px;
  position: relative;
  bottom: 100px;
}

.signup-btn {
  position: relative;
  bottom: 0vh;
  width: 100%;
}

.signup-nav {
  //border: 1px solid red;
  justify-content: space-around;
  justify-self: center !important;
  justify-items: center;
  flex-wrap: wrap;
  width: 90vw !important;
  margin: 0 auto !important;
  position: relative;
  left: 8%;
}

.signup-content {
  //border: 1px solid red;
  width: 25vw;
  margin: 0 auto;
  //justify-content: center;
}

.my-signup-form1 {
  position: relative;
  right: 21%;
}

.my-signup-form2 {
  position: relative;
  left: 101%;
}

.my-signup-form3 {
  position: relative;
  left: 56vw;
}

.RegisterValidation {
  // height: 0px;
}

.signup-title {
  text-align: center;
  position: relative;
  top: 20px;
}

.signupRtrnBtn {
  // position: relative;
  // bottom: 25vh;
  width: 15%;
  margin: 0 auto !important;
  align-self: center;
  position: absolute;
  left: 43vw;
  bottom: -1px;
}

.addSelect {
  max-width: 90% !important;
}
.addSection {
  border: 1px solid;
  border-color: #2b3553;
  font-weight: 800;
  border-left: 0px;
  border-radius: 4px;
  position: relative;
  text-transform: uppercase;
  width: 20%;
  right: 4px;
  font-size: 20px;
  transition: all 0s !important;
}

.addSection :hover {
  font-weight: bolder;
  color: white;
  font-size: 27px;
}

.addTheme {
  transition: 0s;
  color: rgb(78, 78, 78);
}

.addTheme :hover {
  font-weight: bolder;
  color: black;
  font-size: 27px;
}

.addIcon {
  cursor: pointer;
  align-self: center;
  justify-self: center;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  // border: 1px solid red;
  // margin-left: 3%;
  // border-color: #2b3553;
  // border-radius: 0.4285rem;
}
