.modal-addInstallator-wrapper {
    position: fixed;
    background: rgba(0, 0, 0, 0.233);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 500;
}

.modal-addInstallator-content {
    background: #24223a;
    width: 100%;
    max-width: 800px;
    height: 100%;
    max-height: 250px;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
    //color: rgb(0, 0, 0);
    font-family: "Poppins", sans-serif !important;
    //bottom: 100px;
    //text-align: center;
}

.add-header-section {
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 0%, rgba(0,212,255,1) 100%);  
    height: 9vh;
    align-items: center;
    color: white;
}

.close-icon2 {
    position: absolute;
    right: 0;
    color: #fff;
    padding: 20px;
    font-size: 1.2rem;
    cursor: pointer;
    z-index: 1000 !important;
}

.add-header-section h4 {
    position: absolute;
    top: 6%;
    left: 5%;
    font-size: 25px;
    
}

.lightHeader-add h4 {
    color: white !important;
}

.input-addInst-container {
    margin-top: 10px;
    align-self: center;
    align-content: center;
    justify-self: center;
    justify-items: center;
    width: 80%;
}

.addInst-btn-container button {
    width: 200px;
}

.lightAddInstallator-content {
    background-color: rgb(226, 226, 226);
    color: black;
}

.lightH4 {
    color: white !important;
}

.lightHeaderTitle {
    color: white !important;
}