.workerTypes-btn-container {
  justify-self: center;
  position: relative;
  left: 500px;
  top: 60px;
}

.modal.show .modal-dialog {
  transform: unset !important;
}
.modal {
  overflow-y: hidden !important;
  background-color: rgba(0, 0, 0, 0.5);
  .modal-dialog {
    max-width: 1024px !important;
    width: 1024px !important;

    .modal-content {
      max-width: 1024px !important;
      background: #27293d;
    }
  }
}

.fixed-plugin {
  top: 80px !important;
}

.mySidbarLogo {
  width: 250px;
}

.greeting {
  margin-top: 1%;
}

.icons-tag {
  font-weight: bold;
  font-size: 1.5rem;
  position: absolute;
  top: -12px;
  left: -2px;
}

.userName {
  // position: absolute;
  // //right: 6vw;
  // top: 1.1%;
  margin-top: 2vh;
}
