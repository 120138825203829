.managerProfile-container {
    //border: 1px solid red;
    width: 80vw;
    margin: 0 auto;
}

.my-card-user {
    width: 70vw;
    height: 60vh;
    //border: 1px solid yellow;
    margin: 0 auto !important;
    position: relative;
    left: 18%;
    padding-top: 20%;
    text-align: center;
}

.button-container {
    //width: 100%;
    position: relative;
    bottom: 20px;
}

.fo-btn {
    justify-content: space-evenly;
}
.ret-btn {
    width: 18%;
}

.my-save-btn {
    width: 18%;
}