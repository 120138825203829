* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


a {
    text-decoration: none;
    color: black;
}

ul {
    list-style: none;
}

.app-wrapper {
  //background-image:url('../../assets/img/darken-night-sky-full-of-stars-fantasy-animation-made-of-magical-sparkly-light-particles-flickering-on-a-purple-blue-gradient-background_bajacpz7x_thumbnail-full01.png');
  height: 100%;
  min-height: 100vh;
}

.servelit-logo {
  position: relative;
  //border: 1px solid red;
  width: 200px;
  height: 100px;
  align-self: center;
  margin-top: 10px;
}

.oval {
  min-width: 200px;
  min-height: 200px;
  position: absolute;
  left: 42vw;
  top: 25vh;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// select {
//     background-color: #212529;
//     color: seashell;
//     position: relative;
// }

// .select2 {
//     position: relative;
//     bottom: 0px;
// }

.select3 {
    position: relative;
    bottom: 10px;
}

// input[type=date]::-webkit-datetime-edit-text {
//     -webkit-appearance: none;
//     display: none;
// }
// input[type=date]::-webkit-datetime-edit-month-field{
//     -webkit-appearance: none;
//     display: none;
// }
// input[type=date]::-webkit-datetime-edit-day-field {
//     -webkit-appearance: none;
//     display: none;
// }
// input[type=date]::-webkit-datetime-edit-year-field {
//     -webkit-appearance: none;
//      display: none;
// }




// ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
// ::-webkit-datetime-edit-month-field:not([aria-valuenow]),
// ::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
//     color: transparent;
// }

