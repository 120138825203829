.modal-lm-wrapper {
    position: fixed;
    background: rgba(0, 0, 0, 0.74);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 500;
}

.modal-lm-content {
    background: #e2e2e2;
    width: 100%;
    max-width: 800px;
    height: 100%;
    max-height: 250px;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
    color: rgb(255, 255, 255);
    font-family: "Poppins", sans-serif !important;
    flex-wrap: wrap;
    //bottom: 100px;
    //text-align: center;
}


.lm-title {
    background-color: #1d8cf8;
    h1 {
        margin-top: 2%;
        text-align: center !important;
        width: 100%;
        margin: 0 auto;
        height: 80px;
        position: relative;
        top: 25%;
    }
}

.modal-lm-content {
    p {
        color: black;
        margin-top: 2%;
        text-align: center;
    }

    a {
     text-decoration: underline;
     font-weight: bold;
    }
}


.lm-buttons {
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 30px;
    button {
        width: 180px;
        border-radius: 5%;
        height: 40px;
        //background-color: #fff !important;
    }
}

.btnA {
    background-color: rgb(255, 255, 255);
}

.btnB {
    background-color: black;
    color: white;
}