.comment-line-container {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  min-height: 500px;
  // height: 50%;
  max-height: 500px;

  .comment-modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h4 {
      margin-bottom: 0;
    }
    .close-icon {
      position: relative;
      display: flex;
      padding: 0;
    }
  }
}

.modal-comments-content {
  background: #27293d;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
  text-align: center;
  color: #fff !important;
}

.msgs-container {
  width: 80%;
  align-self: center;
  flex: 1;
  overflow-y: auto;
  padding-right: 16px;
  height: 100%;
  margin-bottom: 1rem;
}
.comment-container {
  position: relative;
  margin-bottom: 20px;
}
.comment-container.nogap {
  margin-top: -20px;
}
.comment-container span {
  max-width: 250px;
}
.comment-container p {
  max-width: 250px;
}

.right {
  text-align: right;
  align-items: flex-end;
}
.left {
  align-items: flex-start;
  text-align: left;
}

.send-comment-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 75%;
  align-self: center;
}

.message-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
}
.message-sender {
  font-weight: bold;
  font-size: 0.9rem;
}
.message-time {
  font-size: 0.7rem;
  color: #fff;
  font-style: italic;
}
.message-text {
  font-size: 1rem;
}
.message {
  position: relative;
  background: #287bf6;
  text-align: right;
  padding: 10px 15px;
  border-radius: 6px;
  border: 1px solid #287bf6;
}

.message::before {
  content: "";
  position: absolute;
  visibility: visible;
  top: -1px;

  border: 10px solid transparent;
  border-top: 10px solid #287bf6;
}
.message.right::before {
  right: -10px;
}
.message.right::after {
  content: "";
  position: absolute;
  visibility: visible;
  top: 0px;
  right: -8px;
  border: 10px solid transparent;
  border-top: 10px solid #287bf6;
  clear: both;
}

.comment-input {
  font-size: rem(24px);
  color: rgb(255, 255, 255) !important;
}

.no-comments-text {
  color: rgb(110, 107, 107) !important;
  font-size: 50px;
  display: flex;
  align-self: flex-end !important;
  text-align: center;
  flex: 1;
}
