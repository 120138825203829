// // Font Sizes
// .fs12 { font-size: rem(12px); }
// .fs14 { font-size: rem(14px); }
// .fs18 { font-size: rem(18px); }
// .fs20 { font-size: rem(20px); }
// .fs24 { font-size: rem(24px); }
// .fs28 { font-size: rem(28px); }
// .fs30 { font-size: rem(30px); }

// flex helpers
.flex {
    display: flex;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-between {
    display: flex;
    justify-content: space-evenly;
}

.flex-between2 {
    display: flex;
    justify-content: space-between;
}

.flex-around {
    display: flex;
    justify-content: space-around;
}

.flex-col-between {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
