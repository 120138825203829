#exportTable {
  .content {
    top: 0 !important;
  }
  .btn-export-container,
  .export-return-btn,
  .btn-fill {
    width: 200px;
  }
  .btn + .btn {
    margin-left: 1rem;
  }
}

.btn-export {
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  opacity: 0;
  width: 100%;
  position: absolute;
  /* right: -2%; */
  right: 0px;
  bottom: 0px;
}

.btn-export-container {
  position: relative;
  text-align: center;
}

.my-export-table {
  text-align: center !important;
}

.my-export-tableCard {
  width: 250%;
}

.celTest {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.my-pagination-top {
  width: 50%;
}



li {
  list-style: none;
}