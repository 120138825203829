// REM Functions
@function rem($size) {
    $remSize: $size / 16px;
    @return ($remSize) * 1rem;
}

// EM Functions
@function em($pixels, $context: 16px) {
    @return ($pixels/$context) * 1em;
}
