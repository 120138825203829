@import "./styledTemplate/black-dashboard-pro-react.css";
@import "../assets/css/nucleo-icons.css";

//SETUPS
@import "setup/base";
@import "setup/typography";
@import "setup/helpers";
@import "setup/functions";
@import "setup/mixins";
@import "setup/variables";

//CMPs
@import "cmps/login.scss";
@import "cmps/signup.scss";
@import "cmps/createClient.scss";
@import "cmps/clientsTable.scss";
@import "cmps/comments.scss";
@import "cmps/managerProfile.scss";
@import "cmps/installProfile.scss";
@import "cmps/dnd.scss";
@import "cmps/filesTable.scss";
@import "cmps/edit.scss";
@import "cmps/dashboard.scss";
@import "cmps/datePicker.scss";
@import "cmps/exportTable.scss";
@import "cmps/links.scss";
@import "cmps/termOfUse.scss";
@import "cmps/addInstallator.scss";
@import "cmps/legal-mention.scss";

.main-panel {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  transition: 0 !important;
}

p.error {
  font-size: 1rem;
  color: red;
  text-align: center;
}

.close-icon {
  display: block !important;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  padding: 20px;
  font-size: 1.2rem;
  cursor: pointer;
  z-index: 1000 !important;
  // pointer-events: none;
  // overflow: ;
}

.password-input-wrapper {
  position: relative;
  i {
    position: absolute;
    right: 1rem;
    top: 25%;
    font-size: 1.25rem;
    cursor: pointer;
    color: #ccc;
    align-self: center;
    display: flex;
    z-index: 1;
  }
}
