.modal-edit-wrapper {
  position: fixed;
  background: rgba(0, 0, 0, 0.767);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 500;
}

.admin-edit-modal {
  position: relative;
  justify-content: center;

  form {
    padding: 1rem;
  }
  .edit-address-group {
    position: relative;
    i {
      cursor: pointer;
      margin-left: 0.25rem;
      color: orange;
    }
    label.error {
      color: rgb(207, 17, 17);
      font-weight: normal;
    }
  }
  h1 {
    font-size: 1.25rem;
    align-self: center;
    display: flex;
    margin-bottom: 1rem;
  }
  .row {
    margin-bottom: 0.5rem;
  }
  .form-control,
  .form-group {
    margin-bottom: 0 !important;
  }
  .section {
    margin-bottom: 2rem;
  }
  .section.actions {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
    button {
      width: 200px;
    }
  }
  .custom-control-label {
    margin-bottom: 0;
  }
  .custom-switch {
    line-height: 1;
    display: flex;
  }
  .products-details {
    margin: 1rem 0;
    .product-line {
      color: #aaa;
    }
    .final-price {
      color: #fff;
      font-size: 1rem;
      line-height: 1;
      margin-top: 0.5rem;
    }
  }
}

.my-react-table {
  text-align: center;
}

.css-yk16xz-control {
  font-size: 16px;
}

.css-1fhf3k1-control {
  background-color: #1d253b !important;
  border-color: #2b3553 !important;
  border-radius: 0.4285rem;
  color: #ffffff;
  cursor: not-allowed !important;
  font-size: 16px;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #1d253b;
  color: #ffffff;
  cursor: not-allowed;
  font-size: 16px;
}

.form-control {
  font-size: 16px;
}

.not-allowed {
  cursor: not-allowed;
}

.edit-return-btn {
  width: 200px;
  font-size: rem(12px);
}

.isFirstDate-label {
  width: 12vw;
  //border: 1px solid red
  white-space: nowrap;
  margin-bottom: 0 !important;
  display: block;
}

.myLightMode {
  .css-2b097c-container {
    .css-yk16xz-control {
      .css-1hwfws3 {
        .css-1uccc91-singleValue {
          color: #222a42 !important;
        }
      }
    }
  }
}

.myLightMode {
  .css-2b097c-container {
    .css-1pahdxg-control {
      .css-1hwfws3 {
        .css-1g6gooi {
          color: #222a42 !important;
        }
      }
    }
  }
}

.myLightMode2 div div div {
  color: black !important;
}

.myLightMode3 div {
  color: black !important;
  background: #e3e3e3 !important;
  border-color: rgba(29, 37, 59, 0.3) !important;
}

.myDarkInput {
  color: black !important;
}

.myFontSize div div div {
  font-size: 12px;
}
