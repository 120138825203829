#legal-mention {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;

  .pdf-container {
    margin: 1rem;
    .pdf-header {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-bottom: 1rem;
      h2 {
        margin: 0;
        padding: 0;
        flex: 1;
      }
    }
    .pdf-content {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .pdf-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
      font-size: 1rem;
      i {
        color: #fff !important;
        font-size: 1.2rem;
        margin: 0 2rem;
      }
    }
  }
}
