* {
  transition: 0 !important;
}

.dashboardAdmin {
  .content {
    top: 80px !important;
  }
  //border: 1px solid red;
  //width: 120%;
  // margin: 0 auto;
  // position: relative;
  // left: 0%;
}

.card-description {
  text-align: center;
}

.my-table {
  //width: 100%;
  width: 90%;
  position: relative;
  margin: 0 auto;
  left: 50px;

  td ul {
    margin-bottom: 0 !important;
  }
}

.my-row {
  cursor: pointer;
}

.my-row-header {
  text-align: center;
}

.my-table-body {
  text-align: center;
}

.filterBtnContainer,
.filterBtnContainer2 {
  width: 100%;
  padding-left: 130px;
  max-height: 40px;
  display: flex;
  position: relative;
  flex: 1;
  margin-bottom: 1rem;
  button {
    margin: 0;
    min-width: 175px;
  }

  button + button {
    margin-left: 0.5rem;
  }
}

.btn3 {
  width: 17%;
}

.getZclient {
  width: 9%;
}

.my-tim-icon {
  color: red;
}

.my-tim-icon2 {
  color: rgb(4, 223, 51);
}
ul li {
  text-align: center;
  vertical-align: middle;
}

.my-table {
  .ReactTable .rt-td {
    padding: 0.25rem 0 !important;
  }
  tr {
    .table-actions {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
      button {
        min-width: 1rem !important;
        margin: 0 !important;
        padding: 0 !important;
        width: inherit !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        margin-left: 0.5rem !important;
        i {
          font-size: 0.9rem !important;
          width: inherit !important;
          align-self: center !important;
          position: relative !important;
          display: flex !important;
          top: unset !important;
          left: unset !important;
          transform: unset !important;
        }
      }
    }
  }
}

.totalClients {
  font-weight: bolder;
  position: absolute;
  left: 9%;
  bottom: 100%;
}

.pagination-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  margin-bottom: 1rem;

  .center-selects {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .react-select {
      width: 250px !important;
      margin: 0;
    }

    .react-select + .react-select {
      margin-left: 1rem;
    }
  }

  .previous-btn,
  .next-btn {
    display: flex;
    width: 250px;
    justify-content: center;
    &:disabled {
      color: #fff !important;
    }
  }
}

.pj-container {
  display: flex;
  flex-direction: column;
  height: 40px;
  padding-left: 20px;
  color: white;
  // align-items: center;
  justify-content: center;
  svg {
    stroke-width: 0.25px;
    font-weight: 100;
    stroke: #fff !important;
    color: #fff !important;
  }
}
