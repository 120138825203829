#links {
  display: flex;
  flex-direction: column;
  flex: 1;
  .header {
    height: 70px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    h1 {
      margin-bottom: 0;
    }

    .close-btn {
      position: absolute;
      left: 2rem;
      margin: 0;
    }
  }

  .content {
    top: 0px !important;
    flex: 1;
    padding: 2rem;
    padding-top: 0;

    .card {
      padding: 2rem;
    }
    .family-name {
      color: #fff;
      margin-bottom: 0.5rem;
    }

    table {
      width: 100%;
      th,
      td {
        padding-bottom: 1rem;
      }
      th {
        text-transform: uppercase;
        font-weight: bold;
        color: #fff;
      }
      td {
        color: #cfcfcf;
        font-size: 1.1rem;
        i.file-type {
          font-size: 2rem;
        }
        i.action {
          margin: 0;
          padding: 0;
          font-size: 1.3rem;
          margin-right: 1rem;
          display: flex;
        }
      }
      .actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex: 1;
        width: 100%;
        cursor: pointer;
      }
    }
  }
}

// .links-container {
//   margin: 0 auto;
//   text-align: center;
//   flex-wrap: wrap;

//   h1 {
//     padding: 1rem;
//   }
// }
// .urls {
//   //border: 1px solid red;
//   justify-content: space-evenly;
//   flex-wrap: wrap;
// }

// .urls div p {
//   height: 50px;
//   cursor: pointer;
// }

// .theView {
//   align-self: center;
// }

// .closePdvCmp {
//   position: absolute;
//   left: 1rem;
//   top: 1rem;
// }

// .arrows {
//   cursor: pointer;
// }
