.modal-files-wrapper {
  position: fixed;
  background: rgba(0, 0, 0, 0.212);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 500;
}

.modal-files-content {
  background: #27293d;
  width: 100%;
  max-width: 800px;
  height: 100%;
  max-height: 727px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
}
.dragFile-container {
  position: relative;
  top: 20px;
  padding: 2rem;
}

.dnd-container {
  height: 200px !important;
  // min-height: 20%;
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  border: 2px dotted rgb(8, 57, 189);
  position: relative;
  overflow-y: auto;
  margin-bottom: 1rem;
  p {
    color: inherit !important;
    text-align: center;
    display: flex;
  }
}

.close-dnd {
  width: 20px !important;
  position: absolute;
  right: 0px;
  border-radius: 5px;
}

.upload-file-btn {
  position: relative;
  top: 40px;
}

.dropzone {
  // border: 1px solid red;
  // height: 100%;
  //height: 100px;
}

.dropzone p {
  position: relative;
  color: rgb(170, 170, 170);
  display: flex;
  flex: 1;
  text-align: center;
  align-items: center;
  //border: 1px solid red;
}

.files-header {
  position: relative;
  //bottom: 50px;
}
.dnd-ul {
  color: rgb(255, 255, 255);
  font-weight: bold;
  position: relative;
  top: 50px;
}

// .files-links-container {
//     //border: 1px solid red;
//     position: relative;
//     top: 20px;
//     height: 200px;
// }

// .files-links-container h4 {
//     color: black !important;
// }

.files-links {
  margin-left: 10px;
  height: 100%;
  //max-height: 450px;
  //border: 1px solid salmon;
  position: absolute;
  left: 0px;
  justify-content: space-evenly;
}

.files-links p {
  color: black !important;
}

.file-row {
  //border: 1px solid red;
  width: 250px;
  justify-content: space-between;
  cursor: pointer;
}

.btn-dnd-container {
  position: relative;
  width: 100%;
  display: flex;
  flex: 1;
  justify-items: center;
  align-self: center;
  align-content: center;
  justify-content: space-evenly;
  margin-bottom: 1rem;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form-group {
    margin-bottom: 0;
  }
  .flex-between {
    margin-top: 1rem;
  }
}

.close-dnd-container {
  width: 136.8px;
}

.dnd-checkbox-container {
  justify-content: space-between;
}

.check-dnd {
  position: relative;
  right: 50%;
}

.ttt {
  height: 100%;
  width: 110%;
  overflow-y: auto;
}

.td-file {
  text-align: center !important;
}

.switch-label {
  position: relative;
  right: 5%;
}
